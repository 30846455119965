module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0__encoding@0.1.1_emgddjr62qeapufabnpo56ph6i/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"一楼一凤 161f","short_name":"一楼一凤","description":"一楼一凤, 161f.com 是全国最全的小姐信息平台, 外围女工作室, 一夜情, 约炮性息, 本地交友","start_url":"/","background_color":"#ffe6e3","theme_color":"#f39","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a683d6c0a75ea842bc44cfe72cc229d0"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-remark-images@7.13.1_gatsby-plugin-sharp@5.13.1_gatsby@5.13.7_babel-eslint@10.1.0_esli_4lqc5ljayj4jultrbmss3kf4yi/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"quality":60,"linkImagesToOriginal":false,"srcSetBreakpoints":[340,520,890],"maxWidth":650,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-remark-autolink-headers@6.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0__encodi_yoy6rwcbwrlfklhi2t6tdd5ysa/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-gtag@5.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0__encoding@0._6bmg4y4p7b2s25z4odhtjedcg4/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-NVE606L03N"],"gtagConfig":{}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-nprogress@5.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0__encoding@0.1._ebqxp27jhocub7v3ax7kjyvxu4/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#f39"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-catch-links@5.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0__encoding@0._g3sbft7zlwmdhoal2z2xndv2ei/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-offline@6.13.2_gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0__encoding@0.1.13_owsuqcgc57xqktavilb42igzai/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0__encoding@0.1.13_react-dom@18.2.0_react@18.2._z52jsc5kyve7fnivrfj5if26le/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
